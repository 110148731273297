import React from "react"
import { Helmet } from "react-helmet"

import Header from "../components/headers/header"
import Footer from "../components/footers/footer"


export default function index() {

  return (
    <div className="bg-white">
      <Helmet>
        <title>Géotechnique Maison</title>
        <meta name="description" content="Géotechnique Maison" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="bg-gray-50 pt-12 sm:pt-16 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Merci pour votre demande !</span>
                <span className="block">Nous allons revenir vers vous rapidement.</span>
                </h2>
                <div className="mt-8 flex justify-center">
                    <p>
                        <a className="text-blue-500" href="/">Revenir à l'accueil</a>
                    </p>
                </div>
            </div>
          </div>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}